import React, {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import { fetchTokenDetails } from '../api/BaseCall';
import Loading from '../components/Loading';
import DisplayTokenInfo from '../components/DisplayTokenInfo';
import NotFoundPage from './NotFound';



const TokenPage = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [tokenDetails, setTokenDetails] =  useState()
  useEffect(() =>{
    const getData = async() =>{
      try {
        const result = await fetchTokenDetails(token)
        setTokenDetails(result)
      } catch (error) {
        setError(error.message)
      } finally{
        setLoading(false)
      }
    }
    getData()
  }, [token])

  if(loading){
    return(<Loading/>)
  }

  if(error){
    return(<NotFoundPage/>)
  }


  return (
    <DisplayTokenInfo tokenDetails={tokenDetails}/>
  )
}

export default TokenPage