import {useState} from 'react'
import { format } from 'date-fns'
import { Box, 
    Card, 
    CardContent, 
    Typography, 
    CardActions, 
    Button, 
    Table, 
    Paper,
    TableContainer,  
    TableRow, 
    TableCell,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Snackbar,
    Alert,
    TableBody,
} from "@mui/material"
import { approveToken, denyToken } from '../api/BaseCall'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const DisplayTokenInfo = ({tokenDetails}) => {
    const [openDailogDeny, setOpenDailogDeny] = useState(false)
    const [openDailogApprove, setOpenDailogApprove] = useState(false)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState()
    if (!tokenDetails || tokenDetails.length === 0){
        return (<div>There is no data for the token</div>)
    }
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy hh:mm a');
    }
    const StyledRow = ({ label, value }) => {
        return (
          <TableRow>
            <TableCell sx={{fontWeight: 'bold'}} >{label}</TableCell>
            <TableCell>{value}</TableCell>
          </TableRow>
        )
      }

    const table_data = (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <StyledRow label="Email:" value={tokenDetails.email}/>
                        <StyledRow label="IP Address:" value={tokenDetails.ip} />
                        <StyledRow label="Date & Time:" value={formatDate(tokenDetails.created_at)} />
                    </TableBody>
                </Table>
            </TableContainer>        
        </>
    )

    const card = (
        <>
            <CardContent>
                <Typography variant='h4' gutterBottom color='Black'>
                    Login Attempt details
                </Typography>
                <Typography variant="body" component="div" sx={{marginBottom:'10px'}}>
                    You have attempted to login to your mail id below is the details,
                    
                </Typography>
                {table_data}
                <Typography padding={2} variant="body">
                    <br/>
                    {tokenDetails.active ? "You have already allowed the login. " : null}
                    { tokenDetails.active ? null : "Click ALLOW, If you want to allow. "}
                    Click DENY, If you wants to disallow.
                </Typography>
            </CardContent>
            <CardActions>
                {tokenDetails.active ? null :
                <Button variant='contained' color='success' sx={{margin:2}} onClick={()=> setOpenDailogApprove(true)} 
                size="medium" ><CheckCircleOutlineIcon/>&nbsp;  Allow</Button>}
                <Button variant='contained' color='warning' sx={{margin:2}} onClick={()=> setOpenDailogDeny(true)} 
                size="medium" ><CancelOutlinedIcon/>&nbsp;  Deny</Button>
            </CardActions>
        </>
    )
    

    const handleApprove = async() =>{
        try {
            const result = await approveToken(tokenDetails)
            console.log(result)
            setMessage('Successfully Approved')
            setSeverity('success')
            window.location.reload(false)
        } catch (error) {
            setMessage('While approving some issue Accord, retry again')
            setSeverity('error')
        }finally{
            setOpenDailogApprove(false)
            setOpen(true)
        }
    }

    const handleDeny = async() =>{
        try {
            const result = await denyToken(tokenDetails)
            console.log(result)
            setMessage('Successfully Denied login')
            setSeverity('success')
        } catch (error) {
            setMessage('While denying some issue Accord, retry again')
            setSeverity('error')
        }finally{
            setOpenDailogDeny(false)
            setOpen(true)
        }
    }

    return (
        <>
            <Box sx={{  display:'flex', alignContent:'center', justifyContent:'center', margin: 2}}  >
                <Card variant='outlined'>{card}</Card> 
                {/* Delete Dialog display */}
                <Dialog open={openDailogDeny} onClose={()=> setOpenDailogDeny(false)}>
                    
                    <DialogTitle>Are you sure...</DialogTitle>
                    <DialogContent>
                        <DialogContentText>To delete the session information.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=> setOpenDailogDeny(false)}>No</Button>
                        <Button onClick={handleDeny}>Yes</Button>
                    </DialogActions>
                </Dialog>  
                {/* Approve Dialog box */}
                <Dialog open={openDailogApprove} onClose={()=> setOpenDailogApprove(false)}>                    
                    <DialogTitle>Are you sure...</DialogTitle>
                    <DialogContent>
                        <DialogContentText>are you sure wants to allow this login.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=> setOpenDailogApprove(false)}>No</Button>
                        <Button onClick={handleApprove}>Yes</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar open={open} autoHideDuration={5000} onClose={()=> {setOpen(false)}} 
                    anchorOrigin={{ vertical:'top', horizontal:'center' }} >
                    <Alert security={severity} variant="filled" sx={{width:'100%'}}>{message}</Alert>
                </Snackbar>
            </Box>
        </>
    )
}

export default DisplayTokenInfo