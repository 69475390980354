import '../styles/NotFoundPage.css'; // Import your CSS file

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h4>Invalid token</h4>
        <p>Sorry, it looks like your token has expired or the token details are missing<br/> <br/>Please contact support</p>
      </div>
    </div>
  )
}

export default NotFoundPage;
